<template>
  <div>
    <NavbarCommon :title="title" :back="back"></NavbarCommon>
    <div v-if="testinfo">
      <div class="title">
        {{ this.title }}
        <span class="tocbtn" @click="toCourse">返回课程</span>
      </div>
      <div class="info">
        本节题试: 单选 {{ this.pointQuestionNum.numofsingle }} 道 /多选
        {{ this.pointQuestionNum.numofmulti }} 道 / 主观
        {{ this.pointQuestionNum.numofsubjective }} 道
      </div>
      <div class="info">
        本节您已测试 {{ this.testinfo.testtimes }} 次<br />最高分
        {{ this.testinfo.max }} 分，最低分 {{ this.testinfo.min }} 分，平均分
        {{ this.testinfo.avg }} 分
      </div>
      <!-- 居中 -->
      <van-row
        type="flex"
        justify="center"
        v-if="
          this.pointQuestionNum.numofmulti +
            this.pointQuestionNum.numofsingle +
            this.pointQuestionNum.numofsubjective >
          0
        "
      >
        <van-col span="9">
          <div
            class="btn"
            @click="toExamination(testinfo.pointid, title, !testinfo.done)"
          >
            继续测试
          </div>
        </van-col>
        <van-col span="9">
          <div class="btn" @click="toError(testinfo.pointid, title)">
            查看错题
          </div>
        </van-col>
      </van-row>

      <van-row class="listtitle">
        <van-col span="5">总分</van-col>
        <van-col span="5">得分</van-col>
        <van-col span="14">测试时间</van-col>
      </van-row>
      <div
        class="list"
        v-for="paper in this.testinfo.paperlist"
        :key="paper.id"
      >
        <van-row>
          <van-col span="5">{{ paper.totalscore }}</van-col>
          <van-col span="5">{{ paper.defen }}</van-col>
          <van-col span="14">{{ paper.subtime }}</van-col>
        </van-row>
      </div>
    </div>

    <div v-else>
      <van-loading v-if="isLoadingFished" size="6.667vw" vertical
        >加载中...</van-loading
      >
    </div>
  </div>
</template>
<script>
import NavbarCommon from '@/components/common/NavbarCommon'
export default ({
  data () {
    return {
      title: '',
      back: 'pointinfo',
      getNumOfPoint: true
    }
  },
  components: {
    NavbarCommon
  },
  mounted () {
    // console.log(this.$store.state.study.pointQuestionNum, 'num')
    this.title = localStorage.getItem('Testpname')
    this.uid = localStorage.getItem('uid')
    this.TestPointid = localStorage.getItem('TestPointid')
    this.classid = localStorage.getItem('classid')
    this.courseid = localStorage.getItem('StudyCourseId')
    console.log(this.testinfo, 'testinfo')

    // 试题总量
    // if ((this.$store.state.study.pointQuestionNum.pointid !== this.TestPointid) && this.getNumOfPoint) {
    this.getNumOfPoint = false
    this.$store.dispatch('getNumOfQuestions', {
      uid: this.uid,
      TestPointid: this.TestPointid,
      getType: 'ONLY',
      courseid: 0
    })

    console.log(typeof this.TestPointid, 'TestPointid')
    console.log(this.$store.state.study.pointQuestionNum, 'num')
    // 本节的学习情况 测试次数 等 可以直接从pointlist中获取 getDoneInfo
    // if (this.testinfo.pointid !== this.TestPointid || this.testinfo.length === 0 || ) {
    this.$store.dispatch('getTestInfo', {
      uid: this.uid,
      courseid: this.courseid,
      pointid: this.TestPointid,
      classid: this.classid
    })
    // upate this.$store.state.study.pointslist.data.i
    //   this.uppointlist(this.TestPointid, this.testinfo.avg, this.testinfo.testtimes, this.pointlist)
    //   更新
    console.log(this.pointlist, 'upbefore')
    // console.log(this.testinfo, 'testinfo')
    // this.$nextTick(() => {
    //   console.log('onloaded')
    //   console.log(this.testinfo.avg, 'avg')
    //   console.log(this.testinfo.testtimes, 'testtimes')
    //   this.$store.commit('updatepointlist', this.uppointlist(this.TestPointid, this.testinfo.avg, this.testinfo.testtimes, this.pointlist))
    // })
    // this.$store.commit('updatepointlist', this.uppointlist(this.TestPointid, this.testinfo.avg, this.testinfo.testtimes, this.pointlist))
    // }
    // }
  },
  computed: {
    // pointlist () {
    //   return this.$store.state.study.pointslist
    // },
    pointlist () {
      let points = this.$store.state.study.pointslist
      if (points.length > 0) {
        return points
      } else {
        points = localStorage.getItem('pointslist')
      }
      var pointslistArr = []
      if (points !== null) {
        pointslistArr = JSON.parse(points)
      }
      return pointslistArr
      //   return TestInfoArr
    },
    testinfo () {
      //   let localTestInfo = this.$store.state.study.testinfo
      //   if (localTestInfo.length > 0) {
      //     return localTestInfo
      //   } else {
      //     localTestInfo = localStorage.getItem('TestInfo')
      //   }
      //   var TestInfoArr = []
      //   if (localTestInfo !== null) {
      //     TestInfoArr = JSON.parse(localTestInfo)
      //   }
      return this.$store.state.study.testinfo
      //   return TestInfoArr
    },
    pointQuestionNum () {
      return this.$store.state.study.pointQuestionNum
    }
  },
  watch: {
    testinfo () {
      console.log('onloaded')
      console.log(this.testinfo.avg, 'avg')
      console.log(this.testinfo.testtimes, 'testtimes')
      this.$store.commit('updatepointlist', this.uppointlist(this.TestPointid, this.testinfo.avg, this.testinfo.testtimes, this.pointlist))
    }
  },
  methods: {
    uppointlist (id, avg, testtimes, pointlist) {
      console.log(pointlist, 'upbefore')
      console.log(typeof id, id)
      //   console.log( id)
      for (var i = 0; i < pointlist.data.length; i++) {
        console.log(typeof pointlist.data[i].id, 'pointid')
        if (pointlist.data[i].id === Number(id)) {
          pointlist.data[i].avg = avg
          pointlist.data[i].testtimes = testtimes
          console.log('执行这里')
        }
        if (testtimes >= 5 && avg >= 70) {
          pointlist.data[i].done = '达标'
          if (i < pointlist.data.length - 1) { pointlist.data[i + 1].lock = false }
        }
      }
      console.log(pointlist, 'after')
      return pointlist
    },
    toExamination (id, pname, lock) {
      if (lock) {
        this.$msg('未解锁...')
        return
      }
      localStorage.setItem('TestPointid', id)
      localStorage.setItem('Testpname', pname)
      this.$router.push('/pointTest')
    },
    toError (id, pname) {
      this.$msg('正在建设...')
      //   localStorage.setItem('TestPointid', id)
      //   localStorage.setItem('Testpname', pname)
      //   this.$router.push('/testError')
    },
    toCourse () {
      this.$router.push('/course')
    }
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', false)
  }
})
</script>

<style lang="scss" scoped>
.title {
  color: #444;
  font-size: 3.611vw;
  padding: 2.778vw;
}
.info {
  color: chocolate;
  font-size: 3.611vw;
  padding: 2.778vw;
}
.listtitle {
  color: cornflowerblue;
  background-color: #eee;
  font-size: 3.611vw;
  padding: 1.389vw;
  padding-left: 5.556vw;
}
.list {
  color: chocolate;
  font-size: 3.611vw;
  padding: 1.389vw;
  padding-left: 5.556vw;
}
.btn {
  //   border-radius: 5.556vw;
  background-color: rgb(151, 80, 21);
  color: rgb(244, 245, 204);
  text-align: center;
  padding: 2.778vw;
  margin: 2.778vw;
  font-size: 3.611vw;
}
.tocbtn {
  background-color: #f5ebd0;
  //   display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #f0960f;
  padding: 0.833vw 0.556vw;
  font-size: 3.611vw;
}
</style>
